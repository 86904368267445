import type { GetStaticPropsContext } from 'next'
import assignWith from 'lodash/assignWith'
import commerce from '@lib/api/commerce'
import { languageTerritory } from '@commerce/seo/use-hreflang'
import { GetProductOperation } from '@commerce/types/product'
import { rootQueryType } from '@shopify/types/component-metafields'
import { ORIGIN_URL } from '@shopify/const'
import { Pages } from 'templates'
import type { Page } from '@commerce/types/page'
import getProductPersonalizePopular from '@lib/apis/get-product-personalize-popular'
import { cleatShopKey, clearRelatedProduct, deepMerge } from '@lib/utils/tools'

const REVALIDATE_TIME = 120

export async function getStaticProps({
  preview,
  previewData,
  params,
  locale = 'us',
  locales,
}: GetStaticPropsContext<
  { pages: string[] },
  { __handle: string; __preview_type: rootQueryType }
>) {
  const config = { locale, locales }
  let _previewData = {}

  if (preview && previewData && previewData.__preview_type) {
    const { _preview } = await commerce.getMetafields({
      variables: { slug: previewData.__handle },
      config,
      rootQuery: previewData.__preview_type,
      namespace: 'staging',
      keys: ['_preview'],
    })
    _previewData = {
      ..._preview,
      ...previewData,
    }
  }

  const pagesPromise = commerce.getAllPages({
    config,
    previewData: _previewData,
  })
  const { pages } = await pagesPromise
  const path = params?.pages.join('/')
  const slug = '/home' // 默认，首页使用 handle 为 home 的 page

  const pageItem = pages.find((p: Page) =>
    p.handle ? `/${p.handle}` === slug : false
  )
  const data =
    pageItem &&
    (await commerce.getPage({
      variables: { id: pageItem.id! },
      config,
      previewData: _previewData,
    }))
  const page = data?.page

  if (!page) {
    // 404
    return {
      notFound: true,
      revalidate: REVALIDATE_TIME,
    }
  }
  // sections metafields
  const sectionMetafields = await commerce.getMetafields({
    previewData: _previewData,
    variables: { slug: data?.page?.handle || '' },
    config,
    rootQuery: 'page',
    namespace: 'section',
    keys: new Array(20).fill(0).map((_, index) => `section_${index + 1}`),
  })

  const order = page?.metafields?.component_order || []
  let componentMetafield: any = []
  if (order.length) {
    componentMetafield = await commerce.getMetafields({
      previewData: _previewData,
      variables: { slug: data?.page?.handle || '' },
      config,
      rootQuery: 'page',
      namespace: 'component',
      keys: order,
    })
  }

  // popular products
  const popularProducts =
    componentMetafield?.popularProduct?.blocks?.map((p: any) => {
      p.handle = p.__handle
      p.sku = p.__sku
      return p
    }) || []

  // related products
  const products = page?.metafields?.relatedProducts || []
  const productsPromiseList = products
    .concat(popularProducts)
    ?.map((item: any) =>
      commerce.getProduct({
        previewData: _previewData,
        variables: { slug: item.handle },
        config,
      })
    )

  const results: GetProductOperation['data'][] = await Promise.all(
    productsPromiseList || []
  )
  const relatedProducts = products
    .concat(popularProducts)
    ?.map((product: any, index: any) => ({
      origin: product,
      result: clearRelatedProduct(results[index]?.product || {}),
    }))

  const siteInfoPromise = commerce.getSiteInfo({
    config,
    previewData: _previewData,
  })
  const { brands, shop, categories, metafields } = await siteInfoPromise

  if (metafields) {
    // 优化metafields
    const unUsedKeys = ['collectionV3', 'productListing']
    unUsedKeys.forEach((key) => {
      metafields[key] = null
    })
  }

  const mergeMetafields = assignWith(metafields, page.metafields, (obj, page) =>
    !!page ? page : obj
  )

  const languageAlternates =
    locales?.map((lang) => {
      return {
        hrefLang: languageTerritory(lang || 'us'),
        href: `${ORIGIN_URL}${lang === 'us' ? '' : '/' + lang}`,
      }
    }) || []

  if (languageAlternates?.length > 0) {
    languageAlternates.unshift({
      hrefLang: 'x-default',
      href: languageAlternates[0].href,
    })
  }

  const recommendProducts = await getProductPersonalizePopular(locale)

  const seo = deepMerge(
    {
      ...page.seo,
      title: page.name,
      description: page.bodySummary || page?.seo?.description,
      canonical: `${ORIGIN_URL}/${locale === 'us' ? '' : locale}`,
      languageAlternates,
      openGraph: {
        ...page.seo,
        title: page.name,
        site_name: 'eufy',
      },
    },
    page?.metafields?.seoSetting || {}
  )

  return {
    props: {
      type: 'index',
      preview: !!preview,
      previewData: _previewData,
      brands,
      slug,
      page,
      shop: cleatShopKey(shop),
      categories:
        categories?.map((cat) => ({
          slug: cat.slug,
          path: cat.path,
          name: cat.name,
          seo: cat.seo,
          description: cat.description,
        })) ?? [],
      pageType: metafields?.pageType?.pageType || 'charger',
      seo,
      metafields: {
        ...mergeMetafields,
        sections: sectionMetafields || null,
        components: componentMetafield || null,
      },
      relatedProducts: relatedProducts || [],
      recommendProducts,
    },
    revalidate: REVALIDATE_TIME,
  }
}

export default Pages
